import React from 'react';

function Effects() {
    return (
        <section className="content">

            <h2>Langzeitwirkungen der Geistigen Landesverteidigung</h2>
            <p> Die Geistige
            Landesverteidigung und die Landesausstellung von 1939 haben die Schweizer Gesellschaft stark beeinflusst. In
            einer Zeit großer politischer Spannungen und Bedrohungen durch den Nationalsozialismus entstand eine
            besondere nationale Bewegung. Sie war mehr als nur eine Verteidigungsstrategie - sie formte die kulturelle
            und gesellschaftliche Identität der Schweiz.
            </p>

            <h3>Stärkung der nationalen Identität</h3>
            <p>
                Die Bewegung half dabei, ein gemeinsames Schweizer Identitätsgefühl zu schaffen. Sie brachte die
                verschiedenen Sprachregionen näher zusammen und förderte ein neues Verständnis von nationaler Einheit.
                Die Mehrsprachigkeit und kulturelle Vielfalt wurden als Stärke betont, was zu einem komplexen, aber
                zusammenhängenden nationalen Selbstbild führte.

                Die Idee der Geistigen Landesverteidigung ging über militärische Pläne hinaus. Sie wollte die innere
                Stärke der Bevölkerung gegen totalitäre Ideen stärken und gleichzeitig demokratische Werte schützen.
                Dieser umfassende Ansatz machte die Schweiz in dieser Zeit einzigartig in Europa.
            </p>

            <h3>Kulturelle Auswirkungen</h3>
            <p>
                Die Betonung von Unabhängigkeit und Neutralität prägte die Schweizer Kultur nachhaltig. Der
                "Landigeist", der bei der Landesausstellung 1939 sichtbar wurde, entwickelte sich zu einer wichtigen
                gesellschaftlichen Vorstellung, die lange nachwirkte.

                Künstler, Schriftsteller und Denker interpretierten die nationale Identität neu. Sie schufen ein
                modernes Verständnis von Schweizer Kultur. Die Bewegung förderte Kunst und Literatur, die sich
                selbstbewusst mit nationalen Themen beschäftigte, ohne nationalistisch zu werden. Diese kulturelle
                Diskussion wirkt bis heute nach und beeinflusst das Schweizer Selbstverständnis.
            </p>

            <h3>Gesellschaftlicher Zusammenhalt</h3>
            <p>
                Die Geistige Landesverteidigung wollte eine "Volksgemeinschaft" schaffen, die über soziale und
                wirtschaftliche Unterschiede hinwegging. In einer Zeit großer sozialer Spannungen gelang es der
                Bewegung, einen neuen gesellschaftlichen Zusammenhalt zu erzeugen.

                Arbeiter und Unternehmer, Stadt und Land, verschiedene Sprachregionen - alle wurden Teil einer größeren
                nationalen Geschichte. Diese Idee einer solidarischen Gemeinschaft, die Unterschiede respektiert und
                gleichzeitig eine gemeinsame Identität schafft, ist bis heute ein wichtiges Prinzip der Schweizer
                Gesellschaft.
            </p>

            <h3>Einfluss auf die Nachkriegszeit</h3>
            <p>
                Nach dem Zweiten Weltkrieg veränderte sich die Geistige Landesverteidigung. Sie richtete sich nun gegen
                die befürchtete Ausbreitung des Kommunismus. Sie trug wesentlich zur Entwicklung des modernen Schweizer
                Rechts- und Sozialstaats bei und stärkte das Milizsystem der Armee.

                Die Bewegung half der Schweiz, ihre neutrale Position in einem sich neu ordnenden Europa zu definieren.
                Sie legte den Grundstein für die später entwickelte aktive Neutralitätspolitik und humanitäre Tradition
                der Schweiz.
            </p>

            <h3>Gesellschaftliche Bedeutung heute</h3>
            <p>
                Auch lange nach ihrem offiziellen Ende wird das Erbe der Geistigen Landesverteidigung in aktuellen
                Diskussionen über Schweizer Neutralität, Unabhängigkeit und nationale Identität besprochen. Politische
                Parteien und gesellschaftliche Gruppen greifen immer wieder auf Ideen dieser Bewegung zurück.

                In Zeiten der Globalisierung und europäischen Integration dient die Erinnerung an die Geistige
                Landesverteidigung als wichtiger Bezugspunkt für Gespräche über nationale Eigenständigkeit und
                kulturelle Besonderheiten.
            </p>

            <h3>Vergleich mit heutigen Werten</h3>
            <p>
                Die Betonung von Zusammenhalt, Eigenständigkeit und demokratischen Werten aus der Zeit der
                Landesverteidigung findet sich in vielen aktuellen gesellschaftlichen Themen wieder. Allerdings hat sich
                das Verständnis dieser Werte grundlegend verändert.

                Während die ursprüngliche Bewegung noch stark von einem einheitlichen Nationalverständnis geprägt war,
                sehen moderne Schweizer Generationen nationale Identität als einen dynamischen, offenen und vielfältigen
                Prozess. Die Grundprinzipien der Geistigen Landesverteidigung - Zusammenhalt, Respekt und
                Widerstandsfähigkeit - bleiben jedoch weiterhin wichtig.
            </p>
        </section>
    );
}

export default Effects;