import React from 'react';

function IdeaConcept() {
    return (
        <section className="content">
            <div>
                <h1>Geistige Landesverteidigung: Eine politische und kulturelle Bewegung der Schweiz</h1>
                <p>
                    Die Geistige Landesverteidigung ist eine politische Bewegung die in der Schweiz stattgefunden hat.
                    Während dem Zweiten Weltkrieges, waren die totalitären Ideologien, vor allem Nationalsozialismus und Faschismus,
                    aus den Nachbarländern eine Bedrohung. Als Reaktion entstand die sogenannte geistige Landesverteidigung
                </p>

                <h2>Hauptziele und Ideen</h2>
                <ul>
                    <li>
                        <strong>Stärkung schweizerischer Werte:</strong> Die schweizerischen Werte, nationalen Unabhängigkeit und Bräuchen
                        Ziel war die Förderung von als „schweizerisch“ wahrgenommenen Werten,
                        Bräuchen und der nationalen Unabhängigkeit.
                    </li>
                    <li>
                        <strong>Schaffung einer "Volksgemeinschaft":</strong> Die Bewegung strebte eine Überwindung von Klassengegensätzen an,
                        um eine einheitliche schweizerische Identität zu schaffen, die kulturelle Vielfalt und Mehrsprachigkeit mit einbezog.
                    </li>
                    <li>
                        <strong>Abwehr totalitärer Einflüsse:</strong> Die Geistige Landesverteidigung diente als Schutzschild gegen die
                        Ausbreitung faschistischer, nationalsozialistischer und später kommunistischer Ideologien.
                    </li>
                    <li>
                        <strong>Bewahrung demokratischer Werte:</strong> Der Schutz und die Stärkung der schweizerischen Demokratie standen im Mittelpunkt.
                    </li>
                </ul>

                <h2>Umsetzung und Massnahmen</h2>
                <ul>
                    <li>
                        <strong>Kulturförderung:</strong> Der Bundesrat förderte gezielt Filme, Theater und Literatur, um eine eigenständige
                        schweizerische Kultur zu entwickeln.
                    </li>
                    <li>
                        <strong>Medien und Propaganda:</strong> Anstelle reiner Verbote ausländischer Propaganda wurden eigene Medien, darunter das Radio, gestärkt.
                    </li>
                    <li>
                        <strong>Bildung und Information:</strong> Die militärische Ausbildung beinhaltete die Förderung des „geistig-moralischen Widerstandswillens“
                        von Soldaten und Bürgern.
                    </li>
                    <li>
                        <strong>Internationale Präsenz:</strong> Mit Initiativen wie der Gründung der Kulturorganisation <em>Pro Helvetia</em> und dem Einsatz von
                        Kurzwellensendern sicherte die Schweiz auch im Ausland ihre kulturelle Sichtbarkeit.
                    </li>
                </ul>

                <h2>Besonderheiten der Bewegung</h2>
                <p>
                    Die Geistige Landesverteidigung hatte anfänglich einen stark antideutschen Charakter, da die Betonung der schweizerischen
                    Eigenart insbesondere gegenüber Deutschland erfolgte. Im Tessin entstand mit dem „Elvetismo“ eine spezielle Form, die
                    sich gegen den italienischen Irredentismus richtete. Die Bewegung setzte auf eine positive Besinnung auf die eigenen Werte
                    und deren aktive Verbreitung, anstatt auf reine Abschottung und Verbote. Damit zielte die Geistige Landesverteidigung darauf ab,
                    die Schweiz durch die Stärkung ihrer kulturellen und demokratischen Grundlagen gegen äussere ideologische Bedrohungen zu wappnen.
                </p>
            </div>
        </section>
    );
}

export default IdeaConcept;
